import { defineStore } from "pinia";

export const main = defineStore("main", {
  state: () => ({
    page: 0,
    insurance: [],
    completeInsurance: [],
    snackbarMessage: null,
    snackbarStatus: false,
    token: null,
    witchInputFocused: ''
  }),
});

export const userStore = defineStore("user", {
  state: () => ({
    status: null,
    nationalCode: null,
    name: null,
    family: null,
    fullname: null,
    mobile: null,
    tempMobile: null,
    insuranceId: null,
    haveCompleteInsurance: false,
    completeInsurance: null,
    trackingCode: null,
    reserveNumber: null,
  }),
});
export const adminStore = defineStore("admin", {
  state: () => ({
    id: null,
    name: null,
    family: null,
    mobile: null,
    type: null,
  }),
  actions: {
    verifyAdmin() {
      window.axios.get("/admin/verify").then((resp) => {
        this.id = resp.data.id;
        this.name = resp.data.name;
        this.family = resp.data.family;
        this.mobile = resp.data.mobile;
        this.type = resp.data.type;
      });
    },
  },
});
