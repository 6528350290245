import Vue from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import "./plugins/axios";
import "./assets/scss/style.scss";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import VueCompositionAPI from "@vue/composition-api";
import VueHtmlToPaper from "vue-html-to-paper";
import { adminStore, main } from "@/store";

const options = {
  name: "_self",
  specs: ["fullscreen=no", "titlebar=no", "scrollbars=yes"],
  styles: [
    "/css/bootstrap.min.css",
    "/css/kidlat.css",
    "/css/vuetify.min.css",
    "/css/print.css",
  ],
  timeout: 2000, // default timeout before the print window appears
  autoClose: false, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
};

const pinia = createPinia();
Vue.use(PiniaVuePlugin);
Vue.use(pinia);
Vue.use(VueCompositionAPI);
Vue.use(VueHtmlToPaper, options);
Vue.config.productionTip = false;
Vue.mixin({
  data() {
    return {
      ctrl: false,
    };
  },
  methods: {
    refresh() {
      window.location = "/";
    },
    numberKeyHandler: function (event) {
      if (
        !/(^[0-9۰-۹-Vvرؤ]*$)/.test(event.key) &&
        event.key !== "Backspace" &&
        event.key !== "Tab" &&
        event.key !== "Enter" &&
        event.key !== "Control"
      ) {
        event.preventDefault();
      }
      if (/(^[Vvرؤ]*$)/.test(event.key) && !this.ctrl) {
        event.preventDefault();
      }

      if (event.key === "Control") {
        this.ctrl = true;
      } else {
        this.ctrl = false;
      }
    },

    convertNumbers2English: function (string) {
      if (string) {
        return string
          .toString()
          .replace(/[\u0660-\u0669]/g, function (c) {
            return c.charCodeAt(0) - 0x0660;
          })
          .replace(/[\u06f0-\u06f9]/g, function (c) {
            return c.charCodeAt(0) - 0x06f0;
          });
      }
    },
  },
});

router.beforeEach((to, from, next) => {
  if (to.meta?.permission === "admin") {
    if (!localStorage.token) {
      next("/login");
    } else {
      main().token = localStorage.token;
      adminStore().verifyAdmin();
    }
  }
  next();
});

new Vue({
  vuetify,
  router,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
