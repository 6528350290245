<template>
  <v-app>
    <v-main class="grey lighten-3">
      <nav v-if="$route.meta.permission === 'admin'" class="font-12 font-weight-bold ">
        <router-link to="/admin">صفحه اصلی مدیریت | </router-link>
        <router-link v-if="admin.type==='admin'" to="/admin/insurances">لیست بیمه ها | </router-link>
        <router-link v-if="admin.type==='admin'" to="/admin/completeInsurances">لیست بیمه تکمیلی | </router-link>
        <router-link v-if="admin.type==='admin'" to="/admin/admins">لیست مدیران | </router-link>
        <a @click="logout()">خروج</a>
      </nav>
      <v-container class="main d-flex">
        <router-view/>
        <v-footer fixed class="justify-left">
          <a class="font-12" href="https://hamsadeha.com" target="_blank">توسعه داده شده توسط: تجارت الکترونیک همراه داده</a>
        </v-footer>
      </v-container>
      <v-snackbar
      v-model="mainStore.snackbarStatus"
      color="red"
      :timeout="2000"
      bottom
      content-class="text-center"
      >
        {{
          mainStore.snackbarMessage
        }}
      </v-snackbar>
    </v-main>

  </v-app>
</template>
<script setup>
import {main,adminStore} from "./store";
const mainStore = main()
const admin = adminStore()
</script>
<script>
export default {
  name:'BaseApp',
  methods:{
    logout(){
      this.mainStore.token = null
      localStorage.removeItem('token')
      this.$router.push('/')
    }
  }
}
</script>
<style lang="scss">
.v-application{
  font-family: "IRANSans","Roboto", sans-serif !important;
}
#app {
  text-align: center;
  color: #2c3e50;
}
.main{
  height: 100%;
}
nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
