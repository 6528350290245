<template>
  <div>
    <v-form
        :value="valid"
        @submit.prevent="check()"
    >
      <div class="pa-3 text-center font-weight-bold">داروخانه دکتر لطفعلیانی</div>
      <v-text-field
          label="کدملی"
          v-model="user.nationalCode"
          type="tel"
          max-length="10"
          autofocus
          :rules="[v=> !!v || 'وارد کردن کد ملی اجباری است']"
          @keydown="numberKeyHandler($event)"
          @focus="mainPage.witchInputFocused='nationalCode'"
      ></v-text-field>
      <v-btn type="submit" :loading="loading">ادامه</v-btn>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "StartPage",
  data(){
    return{
      valid:false,
      loading: false
    }
  },
  methods:{
    check(){
      this.loading = true
      this.$axios.post('/users/check',{nationalCode: this.convertNumbers2English(this.user.nationalCode)})
          .then((resp)=>{
            this.loading = false
            if(resp.data.status === 'registered'){
              this.user.fullname = resp.data.user?.fullname
              this.user.mobile = resp.data.user?.mobile
              this.user.tempMobile = resp.data.user?.mobile
              this.user.status = resp.data.status
              this.user.haveCompleteInsurance = resp.data.user?.reserves?.[0]?.haveCompleteInsurance
              this.user.insuranceId = resp.data.user?.reserves?.[0]?.insuranceId
              this.user.completeInsuranceId = resp.data.user?.reserves?.[0]?.completeInsuranceId
            }

            this.mainPage.page = 1
            this.mainPage.witchInputFocused = null
          })
          .catch(()=>{
            this.loading = false
          })
    },
  }
}
</script>
<script setup>
import {userStore} from "@/store";
const user = userStore();

import {main} from "@/store";
// eslint-disable-next-line no-unused-vars
const mainPage = main();

</script>

<style scoped>

</style>