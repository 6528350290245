<template>
  <div class="home d-flex align-center justify-center">
    <v-row>
      <v-col cols="12">
        <v-card class="pa-4 elevation-6 mx-auto" outlined shaped>
          <start-page v-if="mainStore.page===0" />
          <second-page v-if="mainStore.page===1" />
          <finish-page v-if="mainStore.page===2" />
        </v-card>
      </v-col>
      <v-col cols="12" dir="ltr" v-if="mainStore.page !==2">
        <simple-keyboard v-if="showKeyboard" :type="keyboardType" :key="keyboardKey" @onChange="onChange" :input="user[mainStore.witchInputFocused]"/>
      </v-col>
    </v-row>
  </div>
</template>

<script setup>
// @ is an alias to /src
import SimpleKeyboard from "@/components/simple-keyboard.vue";
import {main} from "@/store";
const mainStore = main();

import {userStore} from "@/store";
// eslint-disable-next-line no-unused-vars
const user = userStore();
</script>
<script>
import StartPage from "@/views/users/start";
import SecondPage from "@/views/users/second";
import FinishPage from "@/views/users/finish";

export default {
  name: 'HomeView',
  components: {StartPage,SecondPage,FinishPage},
  data(){
    return{
      keyboardKey: 0,
      keyboardType: 'numeric',
      showKeyboard: true
    }
  },
  mounted() {
    this.mainStore.page=0
    this.getInsurance()
    this.getCompleteInsurance()
  },
  watch:{
    'mainStore.witchInputFocused': function (newValue) {
      this.showKeyboard = false
      const numberNeed = ['nationalCode', 'tempMobile']
      if (numberNeed.includes(newValue)) {
        this.keyboardType = 'numeric'
        this.keyboardKey = !this.keyboardKey

      } else if (newValue === 'trackingCode'){
        this.keyboardType = ''
      this.keyboardKey = !this.keyboardKey
    }
      else{
        this.keyboardType = 'farsi'
        this.keyboardKey = !this.keyboardKey
      }
      this.showKeyboard = true
    }
  },
  methods:{
    onChange(input) {
      if(this.mainStore.witchInputFocused){
        this.user[this.mainStore.witchInputFocused] = input;
      }
    },
    getInsurance(){
      this.$axios.get('/users/insurance/list')
      .then(resp=>{
        this.mainStore.insurance = resp.data
      })
    },
    getCompleteInsurance(){
      this.$axios.get('/users/completeInsurance/list')
          .then(resp=>{
            this.mainStore.completeInsurance = resp.data
          })
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
