<template>
  <div>
    <div id="printMe">
      <div class="align-center ma-0" style="text-align: center">
        <div class="flex">
          <div>داروخانه دکتر لطفعلیانی</div>
          <div class="font-12">
            <div>{{ user.fullname || user.name+' ' +user.family }}</div>
            <div>{{ user.nationalCode }}</div>
          </div>
        </div>
        <div class="flex pt-3">
          <strong>نوبت شما: {{ user.reserveNumber }}</strong>
        </div>
        <div class="flex pt-3" dir="ltr">
          {{ moment(new Date()).format("jYYYY-jMM-jDD HH:mm") }}
        </div>
      </div>
    </div>
    <v-btn class="mt-3" @click="print">چاپ رسید</v-btn>
  </div>
</template>

<script>
const moment = require("moment-jalali");
export default {
  name: "FinishPage",
  data() {
    return {
      valid: false,
    };
  },
  mounted() {
    setTimeout(this.print(), 2000);
  },
  methods: {
    async print() {
      // Pass the element id here
      await this.$htmlToPaper("printMe", null, () => {
        this.refresh();
      });
    },
  },
};
</script>
<script setup>
import { userStore } from "@/store";
const user = userStore();

import { main } from "@/store";
// eslint-disable-next-line no-unused-vars
const mainPage = main();
</script>

<style scoped>
</style>