<template>
  <v-form v-model="valid" ref="insuranceForm" @submit="submit()">
    <div class="pa-3 text-center font-weight-bold">داروخانه دکتر لطفعلیانی</div>
    <v-text-field
      label="کدملی"
      v-model="user.nationalCode"
      type="tel"
      max-length="10"
      :rules="[(v) => !!v || 'وارد کردن کد ملی اجباری است']"
      disabled
    ></v-text-field>
    <v-text-field
      v-if="user.mobile"
      label="موبایل"
      :value="maskMobile(user.mobile)"
      type="tel"
      disabled
      max-length="11"
    >
      <template #[`append-outer`]>
        <v-btn small @click="user.mobile = null;user.tempMobile= null">تغییر موبایل</v-btn>
      </template>
    </v-text-field>
    <v-text-field
      v-else
      label="موبایل"
      v-model="user.tempMobile"
      :rules="[required]"
      type="tel"
      max-length="11"
      @focus="mainStore.witchInputFocused = 'tempMobile'"
    ></v-text-field>
    <v-text-field
      label="نام و نام خانوادگی"
      v-model="user.fullname"
      :rules="[required]"
      @focus="mainStore.witchInputFocused = 'fullname'"
    ></v-text-field>
    <v-autocomplete
      v-model="user.insuranceId"
      :items="mainStore.insurance"
      :rules="[required]"
      item-text="name"
      item-value="id"
      label="نوع بیمه خود را انتخاب کنید"
      @focus="mainStore.witchInputFocused = ''"
    ></v-autocomplete>
    <v-text-field
      v-if="needTrackingCode"
      label="کدپیگیری"
      :rules="[required]"
      v-model="user.trackingCode"
      @focus="mainStore.witchInputFocused = 'trackingCode'"
    ></v-text-field>
    <v-checkbox
      v-model="user.haveCompleteInsurance"
      label="بیمه تکمیلی دارم"
      @focus="mainStore.witchInputFocused = ''"
    ></v-checkbox>
    <v-autocomplete
      v-if="user.haveCompleteInsurance"
      v-model="user.completeInsuranceId"
      :items="mainStore.completeInsurance"
      :rules="[required]"
      item-text="name"
      item-value="id"
      @focus="mainStore.witchInputFocused = ''"
    ></v-autocomplete>
    <v-btn @click="submit()" :loading="loading" class="primary mx-1">دریافت نوبت</v-btn>
    <v-btn @click="refresh()" class="mx-1">انصراف</v-btn>
  </v-form>
</template>

<script>
export default {
  name: "SecondPage",
  data() {
    return {
      valid: false,
      loading: false,
    };
  },
  watch: {
    "user.tempMobile": function (newValue) {
      this.user.tempMobile = this.convertNumbers2English(newValue);
    },
  },
  computed: {
    needTrackingCode() {
      if (this.user?.insuranceId) {
        const current = this.mainStore.insurance.find(
          (item) => item.id === this.user.insuranceId
        );
        return current?.haveTrackingCode;
      }
      return false;
    },
  },
  methods: {
    submit() {
      if (this.$refs.insuranceForm.validate()) {
        this.loading = true;
        this.user.mobile = this.user.tempMobile;
        this.$axios
          .post("/users/reserve", {
            nationalCode: this.user.nationalCode,
            fullname: this.user.fullname,
            mobile: this.user.mobile,
            insuranceId: this.user.insuranceId,
            haveCompleteInsurance: this.user.haveCompleteInsurance,
            completeInsuranceId: this.user.completeInsuranceId,
            trackingCode: this.user.trackingCode,
          })
          .then((resp) => {
            this.loading = false;
            this.user.reserveNumber = resp.data.reserve.reserveNumber;
            this.mainStore.page = 2;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
<script setup>
import { userStore } from "@/store";
import { main } from "@/store";
const user = userStore();

// eslint-disable-next-line no-unused-vars
const mainStore = main();
const required = (v) => {
  return !!v || "این گزینه اجباری است";
};
const maskMobile = (mobile) => {
  if (mobile) {
    return mobile.substring(8, 11) + "****" + mobile.substring(0, 4);
  }
  return mobile;
};
</script>

<style scoped></style>
