<template>
  <div :class="keyboardClass"></div>
</template>

<script>
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import layout from "simple-keyboard-layouts/build/layouts/farsi";

export default {
  name: "SimpleKeyboard",
  props: {
    keyboardClass: {
      default: "simple-keyboard",
      type: String,
    },
    input: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  data: () => ({
    keyboard: null,
  }),
  mounted() {
    let keyOption = {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      display: {
        "{bksp}": "پاک کردن",
        "{tab}":"Tab",
        "{lock}":"Num Lock",
        "{shift}":"Shift",
        "{enter}":"Enter",
        "{space}":"فاصله",
      },
    };

    switch (this.type) {
      case "numeric":
        keyOption = {
          ...keyOption,
          layout: {
            default: ["1 2 3", "4 5 6", "7 8 9", "{shift} 0 _", "{bksp}"],
            shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"],
          },
          theme: "hg-theme-default hg-layout-numeric numeric-theme",
        };
        break;
      case "farsi":
        keyOption = {
          ...keyOption,
          ...layout,
          rtl: true,
        };
        break;
      default:
        keyOption = { ...keyOption };
    }

    this.keyboard = new Keyboard(this.keyboardClass, keyOption);
  },
  methods: {
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === "default" ? "shift" : "default";

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
  // watch: {
  //   input(input) {
  //     // console.log(input);
  //     // this.keyboard.setInput(input);
  //   }
  // }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
